<!--
 * @心中有党，事业理想: 
 * @Author: Petrichor
 * @Date: 2022-05-10 15:22:27
 * @LastEditors: Petrichor
 * @LastEditTime: 2022-05-10 17:37:59
 * @Description: file content
 * @FilePath: \eart_pc\src\views\search.vue
-->
<template>
  <div class="search-main">
    <div class="list-main">
      <div
        class="list-item"
        v-for="(item, index) in searchList"
        :key="'searchList' + index"
        :class="{ 'show-item': !item.name }"
      >
        <img :src="imgUrl + item.logo" class="show-img" />
        <div class="info">
          <div class="name">{{ item.name }}</div>
          <div class="create_at">
            类型:{{ item.type === "showroom" ? "展览" : "作品" }}
          </div>
          <a-button danger size="small">查看详情</a-button>
        </div>
      </div>
    </div>
    <a-pagination
      v-if="searchList.length"
      @change="search(page)"
      v-model:current="page"
      :total="total"
      :show-size-changer="false"
      :hideOnSinglePage="true"
    />
  </div>
</template>

<script>
import { reactive, toRefs, ref, computed, onMounted, watch } from "vue";
import { postGetSearch } from "@/api/index";
import { useRoute } from "vue-router";
import { handleData } from "@/utils/common";
export default {
  setup() {
    const state = reactive({
      page: 1,
      size: 12,
      total: 0,
    });
    const searchList = ref([]);
    const route = useRoute();
    const word = computed(() => {
      return route.query.keyword;
    });
    const search = (page) => {
      state.page = page;
      const data = {
        page: page,
        size: 12,
        word: route.query.keyword,
      };
      postGetSearch(data).then((res) => {
        if (res.code === 200) {
          searchList.value = handleData(res.data.list);
          state.total = res.data.total;
        }
      });
    };
    onMounted(() => {
      search(1);
    });
    watch(word, () => {
      search(1);
    });
    return {
      ...toRefs(state),
      searchList,
      imgUrl: process.env.VUE_APP_IMG_API,
      search,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../index.less";
.search-main {
  padding-bottom: 12px;
}
</style>
