<!--
 * @心中有党，事业理想: 
 * @Author: Petrichor
 * @Date: 2022-05-11 09:02:35
 * @LastEditors: Petrichor
 * @LastEditTime: 2022-05-12 15:40:56
 * @Description: file content
 * @FilePath: \eart_pc\src\views\opus\opus-list.vue
-->
<template>
  <div class="opus-list-main">
    <a-tabs v-model:activeKey="activeKey" centered>
      <a-tab-pane :key="1">
        <div class="radio-group">
          <a-radio-group
            v-model:value="category"
            button-style="solid"
            @change="getOpusList(1)"
          >
            <a-radio-button
              v-for="(item, index) in categoryList"
              :key="'categoryList' + index"
              :value="item.id"
              >{{ item.display_name }}
            </a-radio-button>
          </a-radio-group>
        </div>

        <div class="list-main">
          <div
            v-for="(item, index) in opusList"
            :key="'opusList' + index"
            :class="{ 'show-item': !item.name }"
            class="list-item"
          >
            <img
              v-if="item.logo && item.logo.indexOf('oss') == -1"
              :src="imgUrl + item.logo"
              class="show-img"
            />
            <img v-else :src="item.logo" class="show-img" />
            <div class="info">
              <div class="name">{{ item.name }}</div>
              <div class="create_at">
                发布时间:{{ item.create_at?.slice(0, 10) }}
              </div>
              <a-button danger size="small" @click="toDetails(item.id)"
                >查看详情
              </a-button>
            </div>
          </div>
        </div>
        <a-pagination
          v-if="opusList.length"
          v-model:current="page"
          :hideOnSinglePage="true"
          :show-size-changer="false"
          :total="total"
          @change="getOpusList(page)"
        />
      </a-tab-pane>
      <a-tab-pane v-if="tabTeam" :key="2" tab="团队作品">
        <div class="team-main">
          <div
            v-for="(item, index) in teamList"
            :key="'teamList' + index"
            :class="{ 'show-item': !item.name }"
            class="team-item"
          >
            <div class="info">
              <div class="logo">
                <a-avatar
                  :alt="item.name"
                  :draggable="true"
                  :size="100"
                  :src="imgUrl + item.logo"
                  class="logo-img"
                />
              </div>
              <div class="team-info">
                <div class="title" @click="goTeam(item.id)">
                  {{ item.name }}
                </div>
                <div class="num-info">
                  <div v-if="item.artists && item.artists.total">
                    <span
                      >成员：{{
                        item.artists.total ? item.artists.total : 0
                      }}</span
                    >
                  </div>
                  <div>
                    <span>关注：{{ item.popular }}</span>
                  </div>
                  <div v-if="item.opus && item.opus.total">
                    <span
                      >作品：{{ item.opus.total ? item.opus.total : 0 }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="follow-btn">
                <a-button shape="round" type="primary">
                  <template #icon>
                    <plus-outlined />
                  </template>
                  关注
                </a-button>
              </div>
            </div>
            <a-divider />
            <div v-if="item.opus && item.opus.list" class="person">
              <a-avatar-group
                :max-count="16"
                :max-style="{ color: '#f56a00', backgroundColor: '#fde3cf' }"
              >
                <a-tooltip
                  v-for="(el, i) in item.opus.list"
                  :key="'opusList' + i"
                  :title="el.name"
                  placement="top"
                >
                  <a-avatar :src="imgUrl + el.logo" />
                </a-tooltip>
              </a-avatar-group>
            </div>
          </div>
        </div>
        <a-pagination
          v-if="teamList.length"
          v-model:current="teamPage"
          :hideOnSinglePage="true"
          :show-size-changer="false"
          :total="teamTotal"
          @change="getTeamList(teamPage)"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from "vue";
import { postGetSearch, postGetCategory, postGetTeamList } from "@/api/index";
import { handleData } from "@/utils/common";
import { PlusOutlined } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";

export default {
  components: {
    PlusOutlined,
  },
  setup() {
    const state = reactive({
      activeKey: 1,
      page: 1,
      size: 12,
      total: 0,
      category: 14,
      teamPage: 1,
      teamTotal: 0,
      tabTeam: false,
    });
    const opusList = ref([]);
    const teamList = ref([]);
    const categoryList = ref([]);
    const router = useRouter();
    const getCategory = async () => {
      await postGetCategory({}).then((res) => {
        if (res.code === 200) {
          categoryList.value = res.data;
          state.category = categoryList.value.length
            ? categoryList.value[0].id
            : 0;
        }
      });
    };
    const getOpusList = (page) => {
      state.page = page;
      const data = {
        page: page,
        size: 12,
        type: "opus",
        category: state.category,
      };
      postGetSearch(data).then((res) => {
        if (res.code === 200) {
          opusList.value = handleData(res.data.list);
          state.total = res.data.total;
        }
      });
    };

    const getTeamList = (page) => {
      state.teamPage = page;
      const data = {
        page: page,
        size: 10,
      };
      postGetTeamList(data).then((res) => {
        if (res.code === 200) {
          teamList.value = handleData(res.data.list, 2);
          state.teamTotal = res.data.total;
        }
      });
    };
    const goTeam = (id) => {
      router.push(`/teamShow?id=${id}`);
    };
    onMounted(async () => {
      await getCategory();
      getOpusList(1);
      getTeamList(1);
    });
    const toDetails = (id) => {
      //作品详情
      router.push(`/opusDetails?id=${id}`);
    };
    return {
      ...toRefs(state),
      getOpusList,
      opusList,
      imgUrl: process.env.VUE_APP_IMG_API,
      categoryList,
      teamList,
      getTeamList,
      goTeam,
      toDetails,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../../index.less";

.opus-list-main {
  padding-bottom: 12px;

  .radio-group {
    padding: @main-padding;
    display: flex;
    margin-left: 12px;
  }
}
</style>
