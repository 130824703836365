<template>
  <div class="opus-details">
    <router-link class="back-text" to="/opusList"
      ><arrow-left-outlined />返回</router-link
    >
    <section class="opus-details-wrap" v-if="opusDetails && opusDetails.value">
      <h2 class="title">{{ opusDetails.value.name }}</h2>
      <section class="d-body" v-if="opusDetails && opusDetails.value">
        <img class="user-icon" :src="logoUrl" @error="imgError" />
        <div>
          <h4 class="user-name">
            {{ opusDetails.value.user_info.name || "" }}
          </h4>
          <p class="dec">
            <label>个人简介：</label
            >{{ opusDetails.value.user_info.description }}
          </p>
          <p class="text-item">
            <label>获赞：</label>
            <span>{{ opusDetails.value.popular || 0 }}</span>
            <label class="follow-text">关注：</label>
            <span>{{ opusDetails.value.follow || 0 }}</span>
            <!-- <label>作品：</label>
            <span>{{opusDetails.user_info}}</span> -->
          </p>
        </div>
      </section>
      <!-- <a-empty v-if /> -->
      <!-- <img class="user-icon" v-if="showLogo" src="@/assets/user_icon.png" /> -->
      <img class="user-icon big-img" :src="imgUrl" @error="imgError" />
    </section>
    <div class="no-data" v-else>暂时没有评论</div>
    <section class="d-follow">
      <a-button @click="togglePage(opusDetails.value.id, 1)">上一篇</a-button>
      <a-button class="popular-btn" @click="toPopular"
        ><img src="@/assets/popular_icon.png" />点赞</a-button
      >
      <a-button @click="togglePage(opusDetails.value.id, 2)">下一篇</a-button>
    </section>
    <section class="d-input">
      <a-form-item>
        <a-textarea
          v-model:value="detailsComment"
          :max="30"
          :rows="3"
          required
          placeholder="写下你的评论"
        />
      </a-form-item>
      <a-form-item class="d-submit">
        <a-button type="primary" html-type="submit" @click="submit"
          >评论</a-button
        >
      </a-form-item>
    </section>
    <section class="d-comment">
      <h3 class="all-title">全部评论</h3>
      <p class="line"></p>
      <ul
        v-if="
          dCommentList && dCommentList.value && dCommentList.value.length != 0
        "
      >
        <li
          class="d-comment-item"
          v-for="(item, index) in dCommentList.value"
          :key="index"
        >
          <img src="@/assets/user_icon.png" />
          <div class="hgroup">
            <h3>{{ item.artist_name }}</h3>
            <h5>{{ item.remark }}</h5>
          </div>
          <p class="date">{{ item.create_at }}</p>
        </li>
      </ul>
      <div class="no-data" v-else>暂无数据</div>
      <div class="d-pagination">
        <a-pagination
          v-model:current="currentNum"
          show-quick-jumper
          :total="total"
          @change="onChange"
        />
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { message } from "ant-design-vue";
import { ArrowLeftOutlined } from "@ant-design/icons-vue";
import {
  getOpusInfo,
  getOpusComments,
  getOpusPopular,
  opusCommentSubmit,
} from "@/api/index";
import { opusTogglePage } from "@/api/newApi";
//this.$router.go(-1);
const logoUrl = ref("");
const imgUrl = ref("");
const route = useRoute();
const opusDetails = reactive({});
const dCommentList = reactive([]);
let showLogo = ref(false);
const imgError = () => {
  showLogo.value = true;
};
const detailsComment = ref("");
let currentNum = ref(1);
let total = ref(0);

const init = () => {
  getOpusInfo({ opus_id: route.query.id }).then((res) => {
    if (res.code === 200) {
      opusDetails.value = res.data;
      logoUrl.value =
        opusDetails.value.user_info.logo.indexOf("oss") != -1
          ? opusDetails.value.user_info.logo
          : process.env.VUE_APP_IMG_API + opusDetails.value.user_info.logo;
      imgUrl.value =
        opusDetails.value.logo.indexOf("oss") != -1
          ? opusDetails.value.logo
          : process.env.VUE_APP_IMG_API + opusDetails.value.logo;
    }
  });
};
//全部评论
const getComData = () => {
  getOpusComments({
    opus_id: route.query.id,
    page: currentNum.value,
    size: 10,
  }).then((res) => {
    if (res.code === 200) {
      dCommentList.value = res.data.list;
      total.value = res.data.total;
    }
  });
};
//点赞
const toPopular = () => {
  getOpusPopular({ opus_id: route.query.id }).then((res) => {
    if (res.code === 200) {
      message.success("点赞成功");
    }
  });
};
//评论
const submit = () => {
  if (detailsComment.value) {
    opusCommentSubmit({
      opus_id: route.query.id,
      remark: detailsComment.value,
    }).then((res) => {
      if (res.code === 200) {
        detailsComment.value = "";
        getComData();
        message.success("评论成功");
      }
    });
  } else {
    message.error("请写下你的评论");
  }
};
const togglePage = (id, page) => {
  opusTogglePage({ opu_id: id, front: page }).then((res) => {
    if (res.code == 200) {
      opusDetails.value = res.data;
      logoUrl.value = opusDetails.value.user_info.logo;
      imgUrl.value = opusDetails.value.logo;
    }
  });
};
const onChange = (pageNumber) => {
  currentNum.value = pageNumber;
  getComData();
};
onMounted(() => {
  init();
  getComData();
});
</script>

<style lang="less" scoped>
.opus-details {
  padding: 20px 12%;
  text-align: left;
  .title,
  .user-name {
    font-weight: bold;
  }
  .title {
    margin: 20px 0;
  }
  .opus-details-wrap {
    .user-icon {
      max-width: 100%;
      max-height: 500px;
    }
  }
  .d-body {
    display: flex;
    .user-icon {
      width: 90px;
      margin-right: 20px;
      max-width: 100%;
      margin-bottom: 10px;
    }
    .follow-text {
      margin-left: 10px;
    }
    label {
      color: #8e8e8e;
    }
  }
  .big-img {
    width: 919px;
    height: 500px;
  }
  .d-follow {
    width: 100%;
    height: 63px;
    display: inline-flex;
    padding: 10px 20px;
    margin-bottom: 10px;
    background: rgba(231, 74, 72, 0.05);
    .ant-btn {
      border-radius: 28px;
      border: 1px solid #e74a48;
    }
    .popular-btn {
      width: 100px;
      height: 40px;
      margin: 0 auto;
      background: #e74a48;
      color: #fff;
      img {
        vertical-align: middle;
        margin-right: 5px;
        max-width: 100%;
      }
    }
  }
  .d-input .d-submit {
    text-align: right;
  }
  .d-comment {
    width: 100%;
    padding: 20px 0;
    .all-title {
      text-align: center;
      color: #e74a48;
    }
    .line {
      margin: 2px auto;
      width: 90px;
      height: 1px;
      background: #e74a48;
    }
  }
  .no-data {
    margin: 30px;
    text-align: center;
    color: #666;
  }
  .d-comment-item {
    display: flex;
    img {
      width: 80px;
      height: 80px;
    }
    .hgroup {
      flex: 1;
      margin: 15px;
      h5 {
        color: #585858;
      }
    }
    .date {
      color: #8e8e8e;
    }
  }
  .d-pagination {
    text-align: center;
  }
}
</style>
