<!--
 * @心中有党，事业理想: 
 * @Author: Petrichor
 * @Date: 2022-05-12 14:55:52
 * @LastEditors: Petrichor
 * @LastEditTime: 2022-05-12 16:54:32
 * @Description: file content
 * @FilePath: \eart_pc\src\views\opus\team-show.vue
-->
<template>
  <div class="team-show-main">
    <div class="team-header">
      <div class="logo">
        <a-avatar
          :src="imgUrl + teamInfo.logo"
          class="logo-img"
          :size="120"
          :draggable="true"
          :alt="teamInfo.name"
        />
      </div>
      <div class="team-info">
        <div class="title">
          <a :href="'/teamShow?id=' + teamInfo.id">{{ teamInfo.name }}</a>
        </div>
        <div>团队简介：{{ teamInfo.description }}</div>
        <div class="num-info">
          <div>
            <span>成员：{{ teamInfo.total_artists }}</span>
          </div>
          <div>
            <span>关注：{{ teamInfo.popular }}</span>
          </div>
          <div>
            <span>作品：{{ teamInfo.total_opus }}</span>
          </div>
        </div>
      </div>
      <div class="follow-btn">
        <a-button
          type="primary"
          shape="round"
          @click="popularTeam(teamInfo.id)"
        >
          <template #icon><plus-outlined /></template>
          关注
        </a-button>
      </div>
    </div>
    <a-tabs v-model:activeKey="activeKey" centered>
      <a-tab-pane :key="1" tab="团队成员">
        <div class="team-main">
          <div
            class="team-item"
            v-for="(item, index) in artistsList"
            :key="'artistsList' + index"
            :class="{ 'show-item': !item.name }"
          >
            <div class="info">
              <div class="logo">
                <a-avatar
                  :src="imgUrl + item.logo"
                  class="logo-img"
                  :size="100"
                  :draggable="true"
                  :alt="item.name"
                />
              </div>
              <div class="team-info">
                <div class="title">
                  {{ item.name }}
                </div>
                <div class="num-info">
                  <div>
                    <span>成员：0</span>
                  </div>
                  <div>
                    <span>关注：{{ item.popular }}</span>
                  </div>
                  <div>
                    <span>作品：{{ item.opus_count }}</span>
                  </div>
                </div>
              </div>
              <div class="follow-btn">
                <a-button
                  type="primary"
                  shape="round"
                  @click="popularArtist(item.id)"
                >
                  <template #icon><plus-outlined /></template>
                  关注
                </a-button>
              </div>
            </div>
            <a-divider />
            <div class="person">
              <a-avatar-group
                :max-count="16"
                :max-style="{ color: '#f56a00', backgroundColor: '#fde3cf' }"
              >
                <a-tooltip
                  v-for="(el, i) in item.opus"
                  :key="'opusList' + i"
                  :title="el.name"
                  placement="top"
                >
                  <a-avatar :src="imgUrl + el.logo" />
                </a-tooltip>
              </a-avatar-group>
            </div>
          </div>
        </div>
        <a-pagination
          v-if="artistsList.length"
          @change="getArtists(page)"
          v-model:current="page"
          :total="total"
          :show-size-changer="false"
          :hideOnSinglePage="true"
        />
      </a-tab-pane>
      <a-tab-pane :key="2" tab="团队图片"> </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from "vue";
import { useRoute } from "vue-router";
import { postGetTeamInfo, postGetTeamArtists } from "@/api/index";
import { PlusOutlined } from "@ant-design/icons-vue";
import { handleData } from "@/utils/common";
export default {
  components: {
    PlusOutlined,
  },
  setup() {
    const state = reactive({
      activeKey: 1,
      page: 1,
      size: 4,
      total: 0,
    });
    const route = useRoute();
    const id = route.query.id;
    const teamInfo = ref({});
    const artistsList = ref([]);
    const getTeamInfo = () => {
      postGetTeamInfo({ team_id: id }).then((res) => {
        if (res.code === 200) {
          teamInfo.value = res.data;
        }
      });
    };
    const getArtists = (page) => {
      state.page = page;
      const data = {
        page: page,
        size: 4,
        team_id: id,
      };
      postGetTeamArtists(data).then((res) => {
        if (res.code === 200) {
          artistsList.value = handleData(res.data.list, 2);
          state.total = res.data.total;
        }
      });
    };
    const popularTeam = (teamId) => {
      console.log("关注 team", teamId);
    };
    const popularArtist = (ArtistId) => {
      console.log("关注 art", ArtistId);
    };
    onMounted(() => {
      getTeamInfo();
      getArtists(1);
    });
    return {
      ...toRefs(state),
      teamInfo,
      artistsList,
      imgUrl: process.env.VUE_APP_IMG_API,
      getArtists,
      popularArtist,
      popularTeam,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../../index.less";
.team-show-main {
  padding: 12px 0;
  .team-header {
    margin: @main-padding;
    background: #ffffff;
    padding: 12px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    height: 240px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    .logo {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .team-info {
      flex: 4;
      display: flex;
      //align-items: center;
      justify-content: center;
      flex-direction: column;
      div {
        text-align: left;
        color: #585858;
        margin-bottom: 2vh;
      }
      .title {
        font-size: 18px;
        font-weight: 600;
        color: black;
      }
      .num-info {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        div {
          width: 10%;
          text-align: left;
        }
      }
    }
    .follow-btn {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
