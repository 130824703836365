<!--
 * @心中有党，事业理想: 
 * @Author: Petrichor
 * @Date: 2022-05-11 08:31:01
 * @LastEditors: Petrichor
 * @LastEditTime: 2022-05-11 08:57:58
 * @Description: file content
 * @FilePath: \eart_pc\src\views\showroom\showroom-details.vue
-->
<template>
  <div class="showroom-details-main" v-html="description"></div>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from "vue";
import { useRoute } from "vue-router";
import { postGetShowRoomDetails } from "@/api/index";
export default {
  setup() {
    const state = reactive({
      count: 0,
    });
    const route = useRoute();
    const id = route.query.id;
    const description = ref("");
    const getData = () => {
      let data = {
        sr_id: id,
      };
      postGetShowRoomDetails(data).then((res) => {
        if (res.code === 200) {
          description.value =
            `<h2>${res.data.name}</h2>` + res.data.description;
        }
      });
    };
    onMounted(() => {
      getData();
    });
    return {
      ...toRefs(state),
      description,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../../index.less";
.showroom-details-main {
  padding: @main-padding;
  padding-top: 24px;
}
</style>
