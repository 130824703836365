<template>
  <div class="login-main">
    <img src="@/assets/background.png" class="login-background" />
    <div class="in-main">
      <img src="@/assets/background2.png" class="login-background2" />
      <div class="info">
        <img src="@/assets/login-logo.png" class="logo" @click="toHome" />
        <div class="title">
          {{ flag ? (flag === 1 ? "注册" : "修改密码") : "登录" }}
        </div>
        <div class="e-title">
          {{ flag ? (flag === 1 ? "Register" : "Change Password") : "Login" }}
        </div>
        <div class="form-main">
          <a-form
            v-if="!flag"
            :model="loginForm"
            name="basic"
            autocomplete="off"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }"
            @finish="loginOk"
            @finishFailed="onFinishFailed"
          >
            <a-form-item
              label="账号"
              name="name"
              :rules="[
                { required: true, message: '请输入账号' },
                { pattern: /^1[3456789]\d{9}$/, message: '格式不正确' },
              ]"
            >
              <a-input
                v-model:value="loginForm.name"
                type="tel"
                placeholder="请输入账号"
              />
            </a-form-item>

            <a-form-item
              label="密码"
              name="passwd"
              style="margin-bottom: 0px"
              :rules="[{ required: true, message: '请输入密码' }]"
            >
              <a-input-password
                v-model:value="loginForm.passwd"
                placeholder="请输入密码"
              />
            </a-form-item>
            <a-form-item label="" :wrapper-col="{ offset: 4, span: 20 }">
              <div class="go-link">
                <a-button type="link" danger @click="goLink(2)"
                  >忘记密码？</a-button
                >
                <a-button type="link" danger @click="goLink(1)"
                  >去注册</a-button
                >
              </div>
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 0, span: 24 }">
              <a-button type="primary" html-type="submit">登录</a-button>
            </a-form-item>
          </a-form>
          <a-form
            v-if="flag === 1"
            :model="registerForm"
            name="basic"
            autocomplete="off"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 17 }"
            :validate-messages="validateMessages"
            @finish="registerOk"
            @finishFailed="onFinishFailed"
          >
            <a-form-item
              label="姓名"
              name="name"
              :rules="[{ required: true, message: '请输入姓名' }]"
            >
              <a-input
                v-model:value="registerForm.name"
                placeholder="请输入姓名"
              />
            </a-form-item>
            <a-form-item
              label="手机号"
              name="mobile"
              :rules="[
                { required: true, message: '请输入手机号' },
                { pattern: /^1[3456789]\d{9}$/, message: '格式不正确' },
              ]"
            >
              <a-input
                v-model:value="registerForm.mobile"
                type="tel"
                placeholder="请输入手机号"
              />
            </a-form-item>

            <a-form-item label="邮箱" :rules="[{ type: 'email' }]">
              <a-input
                v-model:value="registerForm.email"
                type="email"
                placeholder="请输入邮箱"
              />
            </a-form-item>
            <a-form-item
              label="密码"
              name="passwd"
              :rules="[{ required: true, message: '请输入密码' }]"
            >
              <a-input-password
                v-model:value="registerForm.passwd"
                placeholder="请输入密码"
              />
            </a-form-item>
            <a-form-item
              label="重复密码"
              name="passwd2"
              :rules="[{ required: true, message: '请再次输入密码' }]"
            >
              <a-input-password
                v-model:value="registerForm.passwd2"
                placeholder="请再次输入密码"
              />
              <div
                class="ant-form-item-explain-error"
                v-if="registerForm.passwd !== registerForm.passwd2"
              >
                两次输入的密码不一致
              </div>
            </a-form-item>
            <a-form-item
              label="性别"
              name="gender"
              :rules="[{ required: true, message: '请选择性别' }]"
            >
              <a-radio-group v-model:value="registerForm.gender">
                <a-radio value="1">男</a-radio>
                <a-radio value="2">女</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 0, span: 24 }">
              <a-button type="primary" html-type="submit"
                >同意协议并注册</a-button
              >
              <div style="margin-top: 12px">
                已有账号？<a-button type="link" danger @click="goLink(0)"
                  >立即登录</a-button
                >
              </div>
            </a-form-item>
          </a-form>
          <a-form
            v-if="flag === 2"
            :model="pwdForm"
            name="basic"
            autocomplete="off"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            @finish="pwdSubmit"
            @finishFailed="onFinishFailed"
          >
            <a-form-item
              label="手机号"
              name="account"
              :rules="[
                { required: true, message: '请输入手机号' },
                { pattern: /^1[3456789]\d{9}$/, message: '格式不正确' },
              ]"
            >
              <a-input
                v-model:value="pwdForm.account"
                type="tel"
                placeholder="请输入手机号"
              />
            </a-form-item>

            <a-form-item
              label="验证码"
              name="mcode"
              :rules="[{ required: true, message: '请输入验证码' }]"
            >
              <div class="phone">
                <a-input
                  v-model:value="pwdForm.mcode"
                  placeholder="请输入验证码"
                />
                <a-button type="primary">
                  <span v-show="isShowTime" @click="getCode">获取验证码</span>
                  <span v-show="!isShowTime">{{ count }} s 后获取</span>
                </a-button>
              </div>
            </a-form-item>
            <a-form-item
              label="密码"
              name="passwd"
              :rules="[{ required: true, message: '请输入密码' }]"
            >
              <a-input-password
                v-model:value="pwdForm.passwd"
                placeholder="请输入密码"
              />
            </a-form-item>
            <a-form-item
              label="重复密码"
              name="passwd_repeat"
              :rules="[{ required: true, message: '请输入密码' }]"
            >
              <a-input-password v-model:value="pwdForm.passwd_repeat" />
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 0, span: 24 }">
              <a-button type="primary" html-type="submit">修改密码</a-button>
              <div style="margin-top: 12px">
                已有账号？<a-button type="link" danger @click="goLink(0)"
                  >立即登录</a-button
                >
              </div>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { register, signIn, sendCode } from "@/api/user";
import { resetPwd } from "@/api/newApi";
import Cookies from "js-cookie";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      count: 0,
    });
    const pwdForm = reactive({
      account: "",
      mcode: "",
      passwd: "",
      passwd_repeat: "",
    });
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    const goLink = (flag) => {
      router.push(`/user?flag=${flag}`);
    };
    const validateMessages = {
      required: "${label} is required!",
      types: {
        email: "请输入正确的邮件格式!",
        number: "请输入正确的手机格式!",
      },
      number: {
        range: "${label} must be between ${min} and ${max}",
      },
    };
    //登录
    const loginForm = reactive({
      name: "",
      passwd: "",
    });
    const loginOk = () => {
      signIn(loginForm).then((res) => {
        if (res.code === 200) {
          message.success("登录成功");
          localStorage.setItem("userData", JSON.stringify(res.data));
          localStorage.setItem("token", res.data.token);
          Cookies.set("HYPERF_SESSION_ID", res.data.token);
          router.push(`/`);
        } else {
          message.error(res.msg);
        }
      });
    };
    //注册接口
    const registerForm = reactive({
      name: "",
      mobile: "",
      email: "",
      gender: "1",
      passwd: "",
      passwd2: "",
    });
    const registerOk = (...args) => {
      register(registerForm).then((res) => {
        if (res.code === 200) {
          message.success("注册成功");
          router.push(`/user`);
        }
      });
    };
    //发送验证码
    const isShowTime = ref(true);
    const timer = ref();
    const TIME_COUNT = ref(60);
    const count = ref();

    const getCode = () => {
      if (!pwdForm.account) {
        message.error("手机号不能为空");
        return;
      }
      if (!timer.value) {
        count.value = TIME_COUNT.value;
        isShowTime.value = false;
        sendCode({ mobile: pwdForm.account }).then((res) => {
          if (res.code === 200) {
            message.success("验证码已发送");
          }
        });
        timer.value = setInterval(() => {
          if (count.value > 0 && count.value <= TIME_COUNT.value) {
            count.value--;
          } else {
            isShowTime.value = true;
            clearInterval(timer.value);
            timer.value = null;
          }
        }, 1000);
      }
      // if(pwdForm.account) {

      // } else {
      //   message.error('手机号不能为空');
      // }
    };
    //找回密码
    const pwdSubmit = () => {
      resetPwd(pwdForm).then((res) => {
        if (res.code === 200) {
          message.success("修改成功");
          router.push(`/user?flag=1`);
        } else {
          message.error(res.msg);
        }
      });
    };
    const toHome = () => {
      router.push(`/`);
    };
    return {
      ...toRefs(state),
      pwdForm,
      registerForm,
      loginForm,

      loginOk,
      registerOk,
      onFinishFailed,
      flag: computed(() => {
        return +route.query.flag;
      }),
      goLink,
      getCode,
      pwdSubmit,
      isShowTime,
      timer,
      count,
      TIME_COUNT,
      toHome,
    };
  },
};
</script>

<style lang="less" scoped>
.login-main {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
  }
  .in-main {
    width: 70vw;
    height: 75vh;
    position: relative;
    .login-background2 {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
    .info {
      position: absolute;
      top: 12vh;
      right: 35px;
      width: 32vw;
      height: 50vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .logo {
        width: 60px;
        margin-bottom: 5vh;
        cursor: pointer;
      }
      .title {
        font-size: 24px;
        color: #e74a48;
      }
      .e-title {
        font-size: 12px;
        color: #e74a48;
        margin-bottom: 2vh;
      }
      .form-main {
        flex: 1;
        width: 80%;
        :deep(.ant-form-item-explain-error) {
          text-align: left;
        }
        .go-link {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .phone {
          display: flex;
          justify-content: space-between;
          align-items: center;
          button {
            margin-left: 6px;
          }
        }
      }
    }
  }
}
</style>
