<!--
 * @心中有党，事业理想: 
 * @Author: Petrichor
 * @Date: 2022-05-10 15:22:27
 * @LastEditors: Petrichor
 * @LastEditTime: 2022-05-11 08:47:09
 * @Description: file content
 * @FilePath: \eart_pc\src\views\showroom\showroom-list.vue
-->
<template>
  <div class="showroom-list-main">
    <div class="list-main">
      <div
        class="list-item"
        v-for="(item, index) in showRoomList"
        :key="'showRoomList' + index"
        :class="{ 'show-item': !item.name }"
      >
        <!-- <img :src="imgUrl + item.logo" class="show-img" /> -->
        <img
          v-if="item.logo && item.logo.indexOf('oss') == -1"
          :src="imgUrl + item.logo"
          class="show-img"
        />
        <img v-else :src="item.logo" class="show-img" />
        <div class="info">
          <div class="name">{{ item.name }}</div>
          <div class="create_at">
            发布时间:{{ item.create_at?.slice(0, 10) }}
          </div>
          <a-button danger size="small" @click="goUrl(item.id)"
            >查看详情</a-button
          >
        </div>
      </div>
    </div>
    <a-pagination
      v-if="showRoomList.length"
      @change="getShowRoomList(page)"
      v-model:current="page"
      :total="total"
      :show-size-changer="false"
      :hideOnSinglePage="true"
    />
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from "vue";
import { postGetShowRoomList } from "@/api/index";
import { handleData } from "@/utils/common";
import { useRouter } from "vue-router";
export default {
  setup() {
    const state = reactive({
      page: 1,
      size: 12,
      total: 0,
    });
    const showRoomList = ref([]);
    const router = useRouter();
    const getShowRoomList = (page) => {
      state.page = page;
      const data = {
        page: page,
        size: 12,
      };
      postGetShowRoomList(data).then((res) => {
        if (res.code === 200) {
          showRoomList.value = handleData(res.data.list);
          state.total = res.data.total;
        }
      });
    };
    const goUrl = (id) => {
      router.push(`/showroomDetails?id=${id}`);
    };
    onMounted(() => {
      getShowRoomList(1);
    });
    return {
      ...toRefs(state),
      showRoomList,
      imgUrl: process.env.VUE_APP_IMG_API,
      getShowRoomList,
      goUrl,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../../index.less";
.showroom-list-main {
  padding-bottom: 12px;
}
</style>
